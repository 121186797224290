.dont-click{
    opacity: 0.5;
    pointer-events: none !important;
}
.app-payment {
    width: 100%;
    height: auto!important;
    padding: 1rem 0;
    background-color: var(--primary01);
}
.app-payment .header-logo img {
    height: 2rem;
    line-height: 2rem;
}
#loader {
    background-color: rgba(27, 28, 27, 0.5);
}
.yarl__container {
    background-color: rgba(27, 28, 27, 0.95) !important;
}
.loader-content{
    display: inline-block;
    width: 48px;
    height: 48px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -24px;
    margin-top: -24px;
}
.sbl-circ-path {
    height: 48px;
    width: 48px;
    color: #00a29b;
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #333333;
    animation: rotate_loader 0.6s linear infinite;
}
  
@keyframes rotate_loader {
    0% {
        transform: rotate(0); }
    100% {
        transform: rotate(360deg);
    }
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}
.fade-out {
    animation: fadeOut 0.5s ease-in-out;
}
.slide-up {
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
}
.auto-height {
    height: auto;
    transition: height 0.5s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.verified .fw-semibold {
    position: relative;
}
.verified .fw-semibold:after {
    content: "";
    position: absolute;
    background-image: url(../images/verified.png);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    z-index: 2;
    top: 0;
    margin-left: 4px;
}
.verified-m .fw-semibold {
    position: relative;
}
.verified-m .fw-semibold:after {
    content: "";
    position: absolute;
    background-image: url(../images/verified.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    z-index: 2;
    top: 0;
    margin-left: 3px;
    margin-top: -1px;
}
.verified-i .fw-semibold {
    position: relative;
}
.verified-i .fw-semibold:after {
    content: "";
    position: absolute;
    background-image: url(../images/verified-black.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    z-index: 2;
    top: 0;
    margin-left: 4px;
    margin-top: -3px;
}
.dropzone .dz-preview.dz-image-preview {
    background: transparent;
}
.dropzone .dz-preview .dz-remove{
    width: 28px;
    height: 28px;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 1000;
}
.dropzone .dz-preview .dz-remove .btn,
.dropzone .dz-preview .dz-remove i{
    cursor: pointer !important;
}
.dropzone .dz-preview .dz-image img {
    overflow: hidden;
    width: 120px;
    height: 120px;
}
.pickr .pcr-button {
    height: 3em;
    width: 6em;
}
.pickr .pcr-button::before,
.pickr .pcr-button::after {
    border-radius: 8px;
}

#elements .card-header,
#products .card-header,
#modules .card-header,
.list-group-order {
  touch-action: none;
  cursor: move;
}

.image-uploaded {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    transition: all .3s ease;
}
.image-uploaded:hover{
    opacity: 1;
}
.image-uploaded img{
    border: 2px dashed #6d6d6d;
    background-color: #2d2d2d;
    border-radius: 10px;
}


.image-element {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    transition: all .3s ease;
}
.image-element:hover{
    opacity: 1;
}
.image-element img{
    border: 2px dashed #6d6d6d;
    background-color: #2d2d2d;
    border-radius: 10px;
}
.image-element .content_icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
.image-element .content_icon i.ri-add-box-fill {
    font-size:40px;
    color: #6d6d6d;
    pointer-events: none;
}
.image-element.content_icon_1{
    display: block;
}
.image-element .content_icon_2{
    display: none;
}
.image-element.div-loader .content_icon_1{
    display: none;
}
.image-element.div-loader .content_icon_2{
    display: block;
}
.image-uploaded .content_icon_1, 
.image-uploaded .content_icon_2{
    display: none;
}

.form-switch label,
.form-switch input{
    cursor: pointer;
}
.swal2-icon.swal2-success .swal2-success-fix {
    top: 0.4em;
}

.swal2-container .swal2-html-container {
    font-size: 1rem;
}

.input-group-text{
    line-height: 1;
}

.image-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-contain {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.form-loader{
    position: relative;
}
.input-loader {
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 5px;
}
.div-loader {
    pointer-events: none;
}
.input-loader .loading {
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
}
.input-loader i {
    -webkit-animation: loader 1000ms infinite linear;
    animation: loader 1000ms infinite linear;
    position: absolute !important;
}
.card.custom-card .card-header .card-title {
    text-transform: none;
}
.card-body nav ul{
    margin-top: 1rem;
    margin-bottom: 0;
}

.image {
    position: relative;
    height: 2.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    color: #fff;
    font-weight: 500;
}
.image img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
}
.image.image-xs {
    height: 1.25rem;
}
.image.image-sm {
    height: 1.75rem;
}
.image.image-md {
    height: 2.5rem;
}
.image.image-lg {
    height: 3rem;
}
.image.image-xl {
    height: 4rem;
}
.image.image-xxl {
    height: 5rem;
}
.imagetext {
    width: 12rem;
    height: 12rem;
}
.imagetext img {
    width: 12rem;
    height: 12rem;
    border-radius: 10px;
}
.text-muted-2{
    color: #777;
}

.cke_dialog_background_cover{
    background-color: rgba(27, 28, 27, 0.5) !important;
    opacity: 1 !important;
}
.cke_editor_text {
    border: 1px solid #3b3c3b !important;
    border-top: 0 !important;
    border-radius: 0 0 6px 6px !important;
}
.cke_inner{
    border-radius: 0 0 6px 6px !important;
    background: #1b1d1b;
}

a.cke_path_item:hover,
a.cke_path_item:focus,
a.cke_path_item:active {
    background-color: #495457 !important;
}

.video-container{
    position: relative;
    margin-top: 2rem;
}

.video-container .remove-video{
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
}
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background-color: #000;
    border-radius: 8px;
}
.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}

.input-group .btn {
    padding: .4rem .85rem;
}

.card-scroll {
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
}
.card-scroll-products{
    max-height: 456px;
    min-height: 456px;
    overflow-y: auto;
}
.card-hover{
    position: relative;
    transition: all .2s linear;
}
.card-hover:hover{
    transform: scale(1.04);
}
.card-hover-opacity{
    position: relative;
    transition: all .2s linear;
}
.card-hover-opacity:hover{
    opacity: 0.8;
    transform: scale(1.02);
}
.card-live {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    font-size: .75rem;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, .18);
    padding: .45rem .625rem .25rem .625rem;
    -webkit-backdrop-filter: blur(1.875rem);
    backdrop-filter: blur(1.875rem);
}
.card-img-overlay{
    z-index: 2;
}
.card-img-overlay .fw-semibold{
    text-shadow: 0 0 16px rgba(0,0,0,0.4);
}
.card-img-overlay:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 80%);
    z-index: -1;
    left: 0;
    top: 0;
}
.branches{
    border-bottom: 1px solid var(--default-border);
    margin-bottom: 16px;
    padding-bottom: 16px;
}
.branches:last-child{
    border-bottom: 0;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
    background-color: #232628 !important;
}
.card-height{
    height: 100%;
}
.form-primary {
  border: 1px solid rgb(0, 162, 155);
}
.form-secondary {
  border: 1px solid rgb(35, 183, 229);
}
.form-warning {
  border: 1px solid rgb(245, 184, 73);
}
.form-info {
  border: 1px solid rgb(73, 182, 245);
}
.form-success {
  border: 1px solid rgb(38, 191, 148);
}
.form-danger {
  border: 1px solid rgb(230, 83, 60);
}
.page-wrapper{
    display: flex;
    flex-direction: column;
}
.content-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
}
.header-container{
    background-color: var(--secondary-color);
}
.header-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    background-color: var(--primary-color);
    border-radius: 200px 0 0 200px;
    width: 100%;
}
.header-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.header-avatar{
    padding: 18px;
    width: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.header-avatar-img{
    padding: 10px;
    border-radius: 200px;
}
.header-name{
    color: var(--custom-white);
    font-family: var(--secondary-font-family);
    font-size: 30px;
}
.header-username{
    color: var(--default-body-bg-color);
    font-size: 16px;
}
.header-settings{
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
    align-self: flex-end;
    background-color: var(--primary-color);
}
.menu-icon{
    font-size: 30px;
    color: var(--primary-color);
    background-color: var(--secondary-color);
    border-radius: 100px;
    margin-right: 20px;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-icon:hover,
.menu-icon.active,
.menu-icon.active:hover{
    color: var(--custom-white);
}
.header-menu{
    display: flex;
    justify-content: flex-end;
    background-color: var(--default-body-bg-color);
    padding: 12px 0 12px 14px;
    align-self: flex-end;
    border-radius: 100px 0 0 100px;
}
.menu-icon-text{
    display: flex;
    align-items: center;
    margin-right: 25px;
}
.menu-item-icon{
    font-size: 28px;
    color: var(--default-body-bg-color);
    background-color: var(--custom-white);
    border-radius: 100px;
    margin-right: 14px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-item-text{
    font-size: 15px;
    text-transform: uppercase;
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
}
.menu-icon-text:hover .menu-item-text,
.menu-icon-text.active .menu-item-text,
.menu-icon-text.active:hover .menu-item-text{
    color: var(--custom-white);
}
.sidebar-container{
    padding: 20px;
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--secondary-color);
}
.sidebar-logo{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
}
.sidebar-logo-img{
    width: 60%;
}
.menu-sidebar{
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    font-size: 20px;
    margin-bottom: 20px;
}
.menu-sidebar:last-child{
    margin-bottom: 0;
}
.menu-sidebar:hover,
.menu-sidebar.active,
.menu-sidebar.active:hover{
    color: var(--custom-white);
}

.sidebar-links{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}
.menu-link{
    color: var(--custom-white);
    font-family: 'Shoika Thin';
    font-size: 18px;
    font-weight: 300;
    margin: 6px 0;
}

.sidebar-footer{
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.sidebar-footer-img{
    width: 60px;
}
.menu-footer-link{
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    line-height: 10px;
    cursor: pointer;
}
.form-check-label{
    line-height: 24px;
}

.content-container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 20px;
}

.social-content .img-fluid{
    width: 100%;
}
.text-justify{
    text-align: justify;
}
.social-content p:last-child{
    margin-bottom: 0;
}
.content-text-program p{
    font-size: .813rem;
    font-family: var(--default-font-family);
    font-weight: var(--default-font-weight);
    color: var(--default-text-color);
    margin-bottom: 8px;
}
.content-text-program p:last-child{
    margin-bottom: 0;
}
.link-like{
    cursor: pointer;
}
.fc-event,
.fc-h-event {
    cursor: pointer;
}
.fc-button {
    transition: all .2s linear;
    padding: 0.5em 0.65em 0.3em 0.65em !important;
}
.fc-button-active,
.fc-button-active:hover,
.fc-button:hover {
    background-color: #03968f !important;
    border-color: #03968f !important;
}
.fc-prev-button,
.fc-next-button {
    padding: 0.4em 0.65em !important;
}
.fc-h-event {
    transition: all .2s linear;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 3px 2px 1px 2px;
    cursor: pointer;
    font-size: 13px;
}
.fc-h-event:hover {
    background-color: #03968f;
    border: 1px solid #03968f;
}
.fc-daygrid-dot-event.fc-event-mirror, .fc-daygrid-dot-event:hover {
    background: rgba(0, 0, 0, 0.5);
}
.fc-daygrid-dot-event {
    padding: 5px 0px 3px 0px;
}
.badge {
    padding: .35rem .45rem .25rem .45rem;
}

.btn-play {
    color: var(--primary-color);
    font-size: 16px;
    margin-left: 15px;
    cursor: pointer;
    margin-top: -3px;
}
.btn-play:hover {
    color: #fff;
}
.btn-custom{
    transition: all .2s linear;
}
.btn-custom:hover{
    opacity: 0.9;
    text-decoration: none !important;
}
.bg-subtitle {
    display: inline-block;
    padding: 6px 14px 4px 14px;
    border-radius: 6px;
    color: #fff;
    margin-right: 10px;
}
.image-content ,
.image-content-hover {
    position: relative;
}
.image-content img {
    width: 100%;
    border-radius: 14px;
    cursor: pointer;
    transition: all .2s linear;
}
.image-content img:hover{
    opacity: 0.8;
    transform: scale(1.04);
}

.image-content-hover {
    cursor: pointer;
    transition: all .2s linear;
}
.image-content-hover img {
    width: 100%;
    border-radius: 14px;
    transition: all .2s linear;
}
.image-content-hover:hover img{
    opacity: 0.8;
}
.btn-download{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px 10px 12px;
    background-color: var(--secondary-color);
    border-radius: 10px;
    font-size: 14px;
    z-index: 2;
    cursor: pointer;
    right: 18px;
    bottom: 6px;
    transition: all .2s linear;
}
.btn-download:hover{
    opacity: 0.8;
}
.btn-download span{
    height: 16px;
}
.btn-download svg{
   font-size: 16px;
   margin-right: 6px;
}
.btn svg{
    font-size: 18px;
}
.carousel-images{ 
    margin: 30px 0px;
}
.carousel-images .btn-prev,
.carousel-images .btn-next {
    opacity: 0.5;
}
.carousel-images:hover .btn-prev,
.carousel-images:hover .btn-next {
    opacity: 1;
}

.btn-next,
.btn-prev{
    color: var(--custom-white) !important;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
	height: 100%;
    position: absolute;
	z-index: 999;
	top: 50%;
	transform: translate(0, -50%);
	overflow: hidden;
	text-align: center;
    cursor: pointer;
    transition: all .2s linear;
}

.btn-next:hover,
.btn-prev:hover {
	color: var(--custom-white) !important;
}
.btn-next {
	right: -10px;
}
.btn-prev {
	left: -10px;
}

.btn.btn-social {
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s linear;
}
.btn.btn-social:hover {
    opacity: 0.8;
    text-decoration: none;
}
.line-one{
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.comment-text a,
.post-content a{
    color: #00a29b;
    transition: all .2s linear;
}
.comment-text a:hover,
.post-content a:hover{
    color: #00a29b;
    opacity: 0.8;
    text-decoration: underline;
}

.list-custom .list-group-item{
    cursor: pointer;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    background-color: var(--secondary-color);
    transition: all .2s linear;
}
.list-custom .list-group-item:last-child{
    border: 0 !important;
}
.list-custom .list-group-item:hover,
.list-custom .list-group-item.play-active,
.list-custom .list-group-item.play-active:hover{
    background-color: var(--primary-color);
}
.list-custom h4{
    color: var(--custom-white) !important;
}
.list-arrow{
    color: var(--custom-white) !important;
    font-size: 24px;
    margin-right: -10px;
    opacity: 0.8;
}
.list-play{
    color: var(--custom-white) !important;
    font-size: 24px;
}
.comment-line{
    margin-bottom: 15px;
    padding-bottom: 10px !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.comment-line:last-child{
    border: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}
.m6zwb4v,.m6zwb4v:visited{
    color: var(--primary-color);
    cursor:pointer;
    display:inline-block;
    padding:0px 2px;
    border-radius:4px;
    font-family: 'Shoika Bold';
    -webkit-text-decoration:none;
    text-decoration:none;
}
.m6zwb4v:hover,.m6zwb4v:focus{
    color:var(--primary-color);
    opacity: 0.8;
    outline:0;
}
.m6zwb4v:active{
    color: var(--primary-color);
    background:var(--secondary-color);
}
.mnw6qvm{
    position:absolute;
    min-width:220px;
    max-width:440px;
    background:var(--secondary-color);
    border: 1px solid #363b40;
    border-radius:8px;
    box-shadow:0px 4px 30px 0px rgba(0, 0, 0, 0.8);
    cursor:pointer;
    z-index:2;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-flex-direction:column;
    -ms-flex-direction:column;
    flex-direction:column;
    box-sizing:border-box;
    -webkit-transform:scale(0);
    -ms-transform:scale(0);
    transform:scale(0);
    overflow: hidden;
}
.m1ymsnxd{
    opacity:0;
}
.m126ak5t{
    opacity:1;
}
.mtiwdxc{
    padding:10px;
    display: flex;
    align-items: center;
}
.mtiwdxc:active{
    background-color:var(--primary-color);
}
.myz2dw1{
    padding:10px;
    display: flex;
    align-items: center;
    background-color:var(--primary-color);
}
.myz2dw1:active{
    background-color:var(--primary-color);
}
.mpqdcgq{
    display:inline-block;
    margin-left:8px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    max-width:368px;
    font-size:13px;
    color: var(--color-white);
    height: 18px;
    font-family: 'Shoika Bold';
    opacity: 1;
}
.m1mfvffo{
    display:inline-block;
    width:24px;
    height:24px;
    border-radius:12px;
}
.a_link{
    cursor: pointer;
    display: inline-block;
}
.a_link:hover{
    text-decoration: underline;
}
.divider-bottom{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}
.code-input{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.code-input input{
    width: calc(100% - 130px);
}
.code-input button{
    width: 120px;
}
.btn-back svg {
    color: #fff;
    font-size: 24px;
    margin-top: -2px;
    margin-left: -8px;
}
.btn-back {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    display: inline-flex;
    margin-bottom: 20px;
    transition: all .2s linear;
}
.btn-back:hover {
    color: #fff;
    box-shadow: 0 2px 0 0 #fff;
}
.hotel-title{
    padding-top: 40px !important;
}
.hotel-title .section{
    padding-top: 0 !important;
}
/* MOBILE */
@media (max-width: 440px) {
    .app-payment .header-logo {
        display: inline-block;
        width: 100%;
        text-align: center;
    }
    .hotel-title{
        padding-top: 20px !important;
    }

}